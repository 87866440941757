<template>
  <div>
    <States v-if="checkin.aasmState === 'approved'" :checkin="checkin" class="mb-3" />
    <div v-if="checkin.business && checkin.business.disconnected" class="d-flex gap-2 align-items-baseline text-warning mb-3">
      <i class="far fa-exclamation-circle" />
      <p class="m-0">
        <span class="text-warning">Publishing to GMB is currently unavailable. No connection to profile. For more information, see the</span>
        <a :href="`/locations/${business.locationId}`" class="text-primary">Business Profile</a>.
      </p>
    </div>
    <div v-if="checkin.instagramPartlyPublished" class="d-flex gap-2 align-items-baseline text-warning mb-3">
      <i class="far fa-exclamation-circle" />
      <p class="m-0">
        <span class="text-warning">Failed to post some media on Instagram</span>
      </p>
    </div>
    <div v-if="checkin.aasmState === 'approved' && checkin.localPostObfuscatedId" class="mb-3">
      <a class="d-flex align-items-center gap-2" :href="localPostUrl(checkin)">
        <i class="far fa-link" />
        Link to GBP post
      </a>
    </div>
    <div class="mv-grid" @dragover.prevent @dragenter.prevent>
      <div class="narrow-container">
        <div class="form-field mb-3">
          <label>Business</label>
          <multiselect
            v-model="business"
            :options="businessesOptions"
            :max-height="540"
            :limit="300"
            :options-limit="300"
            :allow-empty="false"
            :disabled="isFieldDisabled()"
            track-by="id"
            label="name"
            placeholder="Pick a business"
            @input="fetchAssociations">
            <template #option="{ option }">
              <div :class="['text-truncate', { 'text-grey': option.$isDisabled }]">
                <span>{{ option.name }}</span>
              </div>
              <small v-if="option.disconnected" class="text-muted">GBP API connection isn't active</small>
              <span class="d-block font-size-14 text-truncate">{{ option.locationData.address }}</span>
              <span class="d-block font-size-14 text-truncate">{{ option.locationData.storeCode }}</span>
            </template>
          </multiselect>
          <span v-if="fieldErrors.business" class="mt-1 small text-danger">{{ fieldErrors.business }}</span>
        </div>

        <div v-if="checkinExternalIdEnabled" ref="externalIdNode" class="form-field mb-3">
          <label>External moment ID</label>
          <input
            v-model="externalId"
            type="text"
            :disabled="isFieldDisabled()"
            placeholder="External moment ID">
          <p v-if="fieldErrors.externalId" class="mt-1 small text-danger">
            <span>This external id is already taken for another moment for this business.</span>
            <a class="d-flex align-items-center" target="_blank" :href="`/gb/moments/${fieldErrors.externalId}/edit`">
              Moment with this external id
              <i class="pl-2 fa-regular fa-arrow-up-right-from-square" />
            </a>
          </p>
        </div>

        <div ref="addressFormFieldNode" class="form-field mb-3">
          <label class="mb-0">
            Address<span class="text-danger">*</span>
          </label>
          <InputAutocomplete v-if="showMap"
            ref="inputAutocomplete"
            :google-map-api-key="googleMapApiKey"
            :disabled="fromOffice || isFieldDisabled()"
            :init-address="formattedAddress"
            :options="optionAutocomplete"
            @keydown.native.enter.prevent
            @updateAddress="updateAddress" />
          <div v-if="isAddressNotSelected" class="mt-2 small text-warning">Select the address from the dropdown to apply it</div>
          <AppCheckbox v-model="fromOffice" :disabled="isFieldDisabled()" class="d-inline-flex mt-2">
            From office
          </AppCheckbox>
        </div>

        <div class="form-field mb-4">
          <label class="mb-0" for="description">
            Text<span class="text-danger">*</span>
          </label>
          <textarea v-model="description"
            required="true"
            :maxlength="descriptionMaxLength"
            rows="4"
            id="description"
            placeholder="Describe in detail your work"
            :disabled="isFieldDisabled()" />
        </div>
      </div>
      <div class="sub-container">
        <MomentMap v-if="showMap" :coords="coords" :google-map-api-key="googleMapApiKey" />
        <AppAddress
          :address-components="addressComponents"
          :checkin="checkin"
          :is-address-edited="isAddressEdited"
          :from-office="fromOffice" />
      </div>
    </div>
    <MomentImages
      class="mb-4"
      :images="images"
      :disabled="isFieldDisabled() || isRequestSent || checkin.aasmState === 'rejected'"
      @setImages="setImages" />

    <MomentVideo :class="['form-field mb-4', { 'mb-5': checkin.publishVideo }]"
      :checkin="checkin"
      :disabled="isFieldDisabled() || isRequestSent"
      @setVideoUrls="setVideoUrls" />
    <div class="mv-grid">
      <div class="narrow-container">
        <div class="form-field mb-3">
          <AppCheckbox v-model="publishVideo" :disabled="isFieldDisabled() || business.$isDisabled">
            Publish videos to GBP
            <i id="video-hint" class="far fa-exclamation-circle text-primary" />
            <BTooltip target="video-hint">
              <div class="text-left">
                Videos attached to the moment will be published in GBP as separate media entities.
                <br>
                Please note: Google might review videos up to 48 hours.
              </div>
            </BTooltip>
          </AppCheckbox>
        </div>

        <div class="form-field mb-3">
          <label>Customer<span class="text-danger">*</span></label>
          <input
            v-model="customer"
            type="text"
            :disabled="checkin.aasmState === 'rejected'"
            placeholder="Customer's name">
        </div>

        <div class="form-field mb-3">
          <label>Categories</label>
          <multiselect
            v-model="category"
            :options="categories"
            :disabled="checkin.aasmState === 'rejected'"
            :max-height="540"
            :limit="300"
            :options-limit="300"
            track-by="id"
            label="title"
            placeholder="Pick a category" />
        </div>

        <div class="form-field mb-4">
          <label>Employee</label>
          <multiselect
            v-model="employee"
            :options="employees"
            :max-height="540"
            :limit="300"
            :options-limit="300"
            :disabled="checkin.aasmState !== 'pending'"
            track-by="id"
            label="fullName"
            placeholder="Pick a employee" />
        </div>

        <div class="form-field">
          <div class="row">
            <label class="col col-sm-2 col-form-label">Schedule</label>

            <div class="col-12 col-sm-8 col-lg-6 pt-2">
              <div class="switch-button switch-button-success">
                <input type="checkbox"
                  id="schedule_moment"
                  v-model="isScheduled"
                  :disabled="isFieldDisabled()">
                <span>
                  <label for="schedule_moment" />
                </span>
              </div>
            </div>
          </div>

          <transition name="slide-animation">
            <div class="row" v-show="isScheduled">
              <label class="col-12 col-sm-2 col-form-label">Schedule for</label>

              <div class="col-12 col-sm-5 col-lg-4">
                <flat-pickr
                  v-model="scheduledAt"
                  class="form-control"
                  :config="config"
                  :disabled="isFieldDisabled()" />
              </div>

              <label class="col-12 col-sm-2 col-lg-6 col-form-label"> {{ business.timeZone }} </label>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <MomentDestinations
      v-if="business"
      :can-edit-destinations="!isFieldDisabled()"
      :selected-business="business"
      :checkin="checkin"
      :fb-enabled="fbEnabled"
      :ig-enabled="igEnabled"
      @updateMomentDestinations="destinations = $event" />
    <MomentReviews
      v-if="business"
      v-model="review"
      :checkin-created-at="checkin?.createdAt"
      :selected-business-id="business.id" />
    <div class="mv-grid">
      <div class="narrow-container d-flex pt-5 pb-6">
        <button
          v-if="isSaveButtonAllowed()"
          class="btn rounded mr-3"
          :disabled="sendingMoment || checkin.aasmState === 'rejected'"
          @click="submitCheckin">
          Save
          <template v-if="checkin.aasmState !== 'approved'">
            Draft
          </template>
        </button>

        <button
          v-if="checkin.aasmState === 'pending' && !moderationRequired"
          class="btn btn-primary rounded mr-3"
          type="button"
          @click.prevent="publish"
          :disabled="disableFormButtons">
          Save and Publish
        </button>

        <button
          v-if="checkin.obfuscatedId && checkin.aasmState === 'pending' && !moderationRequired"
          class="btn btn-outline-danger rounded mr-3"
          type="button"
          @click.prevent="reject">
          Reject
        </button>

        <button
          class="btn rounded"
          type="button"
          @click.prevent="cancel"
          :disabled="isRequestSent">
          Cancel
        </button>

        <button
          v-if="isDeleteButtonAllowed()"
          class="btn btn-outline-danger rounded ml-auto"
          type="button"
          @click.prevent="destroy"
          :disabled="isRequestSent">
          <i class="far fa-trash" />
          <span class='ml-2'>Delete</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import snakeCaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys-deep'
import FlatPickr from 'vue-flatpickr-component'

import { FLATPICKR_WEEKDAYS_MAP } from 'common/constants'
import axiosTransform from '../../../../common/axios'
import AppCheckbox from '../../../components/checkbox'
import InputAutocomplete from '../../../components/google_address_autocomplete'
import MomentMap from './map'
import MomentVideo from './video'
import AppAddress from './address'
import States from './states'
import MomentReviews from './reviews'
import MomentDestinations from './moment_destinations'
import MomentImages from './images'

import { isEmpty } from '../../../../common/helpers'
import { ensureGoogleMap } from '../../../../common/map_helpers'
import { runSwal } from '../../../../common/delete_with_swal'
import { BASE_URL, handleError, MAX_FILES } from '../helpers'

export default {
  components: {
    Multiselect,
    AppCheckbox,
    MomentMap,
    MomentImages,
    MomentVideo,
    MomentReviews,
    MomentDestinations,
    InputAutocomplete,
    AppAddress,
    FlatPickr,
    States
  },
  props: {
    checkin: Object,
    businesses: Array,
    ajaxCategoriesPath: String,
    ajaxEmployeesPath: String,
    ajaxCheckinsPath: String,
    ajaxCheckinImagePath: String,
    googleMapApiKey: String,
    igEnabled: Boolean,
    fbEnabled: Boolean,
    checkinExternalIdEnabled: Boolean,
    moderationRequired: Boolean
  },
  data() {
    return {
      formattedAddress: this.checkin.formattedAddress,
      addressComponents: this.checkin.addressComponents,
      description: this.checkin.description || '',
      scheduledAt: null,
      fromOffice: this.checkin.fromOffice,
      customer: this.checkin.customer || '',
      externalId: this.checkin.externalId || '',
      category: {},
      employee: {},
      images: this.checkin.images,
      publishVideo: this.checkin.publishVideo || false,
      videoUrls: this.checkin.videoUrls || [],
      business: null,
      review: this.checkin.gmbReview || null,
      employees: [],
      categories: [],
      destinations: {},
      coords: null,
      marker: null,
      onDragItem: null,
      isRequestSent: false,
      isScheduled: Boolean(this.checkin.scheduledAt),
      showMap: false,
      foundAddress: null,
      isAddressNotSelected: false,
      isAddressEdited: false,
      fieldErrors: {},
      sendingMoment: false
    }
  },
  created() {
    this.scheduledAt = this.checkin.scheduledAt
    this.optionAutocomplete = {
      lang: 'en',
      fields: ['formatted_address', 'geometry', 'address_components'],
      types: ['address']
    }
    this.MAX_FILES = MAX_FILES

    this.business = this.setBusiness(this.businesses, this.checkin)
    this.setCoordinates(this.checkin.fromOffice)

    this.fetchAssociations()
    this.config = {
      enableTime: true,
      dateFormat: 'd/m/Y H:i',
      minDate: 'today',
      locale: {
        firstDayOfWeek: FLATPICKR_WEEKDAYS_MAP[Styxie.USER.beginningOfWeek]
      }
    }
  },
  mounted() {
    ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey }, () => {
      this.showMap = true
    })

    this.handlePresetFields()
  },
  computed: {
    descriptionMaxLength() {
      if (this.checkin.publishGmb) {
        return 1500
      }
      if (this.checkin.publishInstagram) {
        return 2200
      }

      return null
    },
    businessesOptions() {
      return this.businesses.map((business) => ({
        ...business,
        $isDisabled: !business.active
      }))
    },
    disableFormButtons() {
      return this.isFieldDisabled() || this.isRequestSent
    },
    isScheduledInThePast() {
      if (!this.checkin.scheduledAt) return false
      const DATE_MASK = 'DD/MM/YYYY HH:mm Z'

      const scheduledAt = moment(this.checkin.scheduledAt, DATE_MASK).toDate().getTime()
      const now = new Date().getTime()
      return scheduledAt < now
    },
    isMomentPublished() {
      const isMomentApproved = this.checkin.aasmState === 'approved'
      return isMomentApproved && (!this.checkin.scheduledAt || this.isScheduledInThePast)
    }
  },
  methods: {
    handlePresetFields() {
      const search = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      const {
        business: businessId,
        date
      } = search

      if (businessId) {
        const relatedBusiness = this.businesses.find((business) => business.id === businessId)
        if (relatedBusiness) {
          this.business = relatedBusiness
        }
      }

      if (date) {
        this.isScheduled = true
        const DATE_MASK = 'DD/MM/YYYY'

        this.scheduledAt = moment(date).format(DATE_MASK)
      }
    },
    isFieldDisabled(fieldName = null) {
      const isNotPending = this.checkin.aasmState !== 'pending'
      if (this.moderationRequired && this.checkin.obfuscatedId &&
        (isNotPending || !this.checkin.currentUserCreator)) return true

      const neverDisabled = new Set('review', 'customer', 'categories')
      if (neverDisabled.has(fieldName)) return false
      if (this.isMomentPublished) return true

      return false
    },
    setBusiness(businesses, checkin) {
      if (checkin.business) {
        return businesses.find((bus) => bus.id === checkin.business.id)
      }

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if (urlParams.get('business_id')) {
        return businesses.find((bus) => bus.id === urlParams.get('business_id'))
      }

      return this.businessesOptions.find((business) => !business.$isDisabled) || null
    },
    setCoordinates(fromOfficeValue) {
      if (fromOfficeValue) {
        this.coords = this.business.coords
      } else if (this.foundAddress) {
        this.coords = { lat: this.foundAddress.lat, lng: this.foundAddress.lng }
      } else if (this.checkin.obfuscatedId) {
        this.coords = this.checkin.coords
      } else {
        this.coords = this.isCoordsValid(this.business.coords) ? this.business.coords : { lat: null, lng: null }
      }
    },
    isCoordsValid(coords) {
      return coords?.lat && coords?.lng
    },
    fetchAssociations() {
      if (!this.business) return

      this.categories = []
      this.category = {}
      this.employees = []
      this.employee = {}

      this.setCoordinates(this.fromOffice)

      this.fetchCategories().then(() => {
        if (this.checkin.category) {
          const selectedCategory = this.categories.find((cat) => cat.id === this.checkin.category.id)
          this.category = selectedCategory
        }
      })

      this.fetchEmployees().then(() => {
        if (this.checkin.employee) {
          const selectedEmployee = this.employees.find((emp) => emp.id === this.checkin.employee.id)
          this.employee = selectedEmployee
        }
      })
    },
    fetchCategories() {
      return axios.get(
        this.ajaxCategoriesPath,
        {
          params: { businessId: this.business.id },
          paramsSerializer(json) {
            return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
          },
          ...axiosTransform
        }
      ).then((response) => {
        this.categories = response.data
      })
    },
    fetchEmployees() {
      return axios.get(
        this.ajaxEmployeesPath,
        {
          params: { businessId: this.business.id },
          paramsSerializer(json) {
            return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
          },
          ...axiosTransform
        }
      ).then((response) => {
        this.employees = response.data
      })
    },
    setImages(images) {
      this.images = images
    },
    setVideoUrls(urls) {
      this.videoUrls = urls
    },
    updateAddress(foundAddress) {
      this.isAddressEdited = true
      this.isAddressNotSelected = false
      this.foundAddress = foundAddress
      this.setCoordinates(this.fromOffice)
      if (foundAddress) {
        const { addressComponents, formattedAddress } = foundAddress
        this.addressComponents = camelcaseKeys(addressComponents)
        this.formattedAddress = formattedAddress
      } else {
        this.addressComponents = null
        this.formattedAddress = ''
      }
    },
    isDeleteButtonAllowed() {
      if (!this.checkin.obfuscatedId) return false
      if (!this.moderationRequired) return true
      const isPending = this.checkin.aasmState === 'pending'

      return this.checkin.currentUserCreator && isPending
    },
    isSaveButtonAllowed() {
      const isPending = this.checkin.aasmState === 'pending'

      return !this.checkin.obfuscatedId || !this.moderationRequired || isPending && this.checkin.currentUserCreator
    },
    async submitCheckin(additionalAction = '') {
      /* TODO: this is kinda fix for 422 responce from WLC user (linda user) */
      const validateErrors = []
      if (!this.description) {
        validateErrors.push('Description can\'t be blank')
      }

      if (!this.customer) {
        validateErrors.push('Customer can\'t be blank')
      }

      validateErrors.forEach((errorText) => {
        toastr.error(errorText)
      })

      if (validateErrors.length) return

      if (this.images.filter(({ isDescriptionGenerating }) => isDescriptionGenerating).length) {
        const result = await swal({
          text: 'Image description generation is still in progress. If you submit a moment right now the descriptions wont be added to images.',
          icon: 'warning',
          buttons: {
            cancel: {
              visible: true,
              text: 'Ok, wait',
              value: false,
              className: 'btn btn-success'
            },
            confirm: {
              text: 'Submit anyway',
              value: true,
              className: 'btn btn-danger'
            }
          },
          dangerMode: true
        })
        if (!result) return
      }

      this.isRequestSent = true
      this.fieldErrors = {}
      const formData = new FormData()

      if (!this.fromOffice) {
        if (this.addressComponents && Array.isArray(this.addressComponents)) {
          formData.append('checkin[formatted_address]', this.formattedAddress)
          this.addressComponents.forEach((row) => {
            formData.append('checkin[address_components][][short_name]', row.shortName)
            formData.append('checkin[address_components][][long_name]', row.longName)

            row.types?.forEach((type) => {
              formData.append('checkin[address_components][][types][]', type)
            })
          })
        } else {
          this.isAddressNotSelected = true
          this.$refs.addressFormFieldNode.scrollIntoView({
            behavior: 'smooth'
          })
          this.isRequestSent = false
          return
        }
      } else {
        formData.append('checkin[formatted_address]', '')
      }

      if (!this.business) {
        this.fieldErrors.business = 'This field cannot be empty'
        this.isRequestSent = false
        return
      }

      formData.append('checkin[from_office]', this.fromOffice)
      formData.append('checkin[description]', this.description)
      formData.append('checkin[mobile_business_id]', this.business.id)
      formData.append('checkin[external_id]', this.externalId?.trim())
      formData.append('checkin[review_remote_id]', this.review?.remoteId)
      formData.append('checkin[customer]', this.customer)
      formData.append('checkin[coords][lat]', this.coords.lat)
      formData.append('checkin[coords][lng]', this.coords.lng)
      formData.append('checkin[publish_video]', this.publishVideo)
      formData.append('checkin[scheduled_at]', this.isScheduled ? this.scheduledAt : null)
      formData.append('checkin[employee_id]', this.employee?.id)

      const snakecasedDestinations = snakeCaseKeys(this.destinations)
      Object.keys(snakecasedDestinations).forEach((destinationName) => {
        formData.append(`checkin[${destinationName}]`, snakecasedDestinations[destinationName])
      })

      if (this.videoUrls.length) {
        this.videoUrls.forEach((row) => formData.append('checkin[video_urls][]', row))
      }

      if (!isEmpty(this.category)) {
        formData.append('checkin[mobile_category_id]', this.category.id)
      }

      if (!isEmpty(this.employee)) {
        formData.append('checkin[employee_id]', this.employee.id)
      }

      this.images.forEach((image, i) => {
        const imageId = (image.id) ? image.id : ''
        const imageDescription = image.description ? image.description : ''
        formData.append('images[][file]', image.file)
        formData.append('images[][id]', imageId)
        formData.append('images[][position]', i)
        formData.append('images[][md5]', image.md5)
        formData.append('images[][description]', imageDescription)
        if (image.collageBefore) formData.append('images[][collage_before]', true)
        if (image.collageAfter) formData.append('images[][collage_after]', true)
      })

      if (additionalAction === 'publish') {
        formData.append('publish', true)
      }

      const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

      if (this.sendingMoment) return
      this.sendingMoment = true

      if (this.checkin.obfuscatedId) {
        await this.updateCheckin(formData, headers)
      } else {
        await this.createCheckin(formData, headers)
      }

      this.sendingMoment = false
    },
    updateCheckin(data, headers) {
      return axios.patch(
        `/gb/ajax/moments/${this.checkin.obfuscatedId}`,
        data,
        headers
      ).then(() => {
        $(document).one('turbolinks:load', () => {
          toastr.success('Moment updated successfully')
        })
        Turbolinks.visit(BASE_URL)
      }).catch((err) => {
        this.isRequestSent = false
        this.handleCheckinError(err)
      })
    },
    createCheckin(data, headers) {
      return axios.post(
        this.ajaxCheckinsPath,
        data,
        headers
      ).then(() => {
        $(document).one('turbolinks:load', () => {
          toastr.success('Moment created successfully')
        })
        Turbolinks.visit(BASE_URL)
      }).catch((err) => {
        this.isRequestSent = false
        this.handleCheckinError(err)
      })
    },
    handleCheckinError(error) {
      const NO_CITY_ERROR = 'City can\'t be blank'

      if (error.response.status === 422) {
        const { errors, additional_data: additionalData } = error.response.data
        const isNoCityError = errors.find((errorString) => errorString === NO_CITY_ERROR)

        if (isNoCityError) {
          toastr.error('The selected address doesn\'t belong to any city. Please try another one.')
          return
        }

        const isExternalIdError = additionalData?.field === 'external_id'
        if (isExternalIdError) {
          this.fieldErrors.externalId = additionalData.existing_moment_id
          this.$refs.externalIdNode?.scrollIntoView({
            behavior: 'smooth'
          })
          return
        }
      }

      handleError(error)
    },
    publish() {
      this.submitCheckin('publish')
    },
    reject() {
      this.isRequestSent = true
      axios.post(
        `/gb/ajax/moments/${this.checkin.obfuscatedId}/reject`,
        {},
        {}
      ).then(() => {
        $(document).one('turbolinks:load', () => {
          toastr.success('Moment rejected successfully')
        })
        Turbolinks.visit(BASE_URL)
      }).catch((err) => {
        this.isRequestSent = false
        handleError(err)
      })
    },
    cancel() {
      window.location = BASE_URL
    },
    destroy() {
      runSwal(() => {
        this.isRequestSent = true
        axios.delete(`/gb/ajax/moments/${this.checkin.obfuscatedId}`).then((res) => {
          $(document).one('turbolinks:load', () => {
            toastr.success(res.data.message)
          })
          Turbolinks.visit(BASE_URL)
        }).catch(() => {
          toastr.error('Something went wrong')
          this.isRequestSent = false
        })
      })
    },
    localPostUrl(checkin) {
      return `/gmb/locations/${checkin.business.gmbLocationRemoteId}/local_posts/${checkin.localPostObfuscatedId}/edit`
    }
  },
  watch: {
    fromOffice(val) {
      this.setCoordinates(val)

      if (val && !this.formattedAddress) {
        this.$refs.inputAutocomplete.search = ''
      }
    },
    business(val) {
      if (val) {
        this.employee = {}

        if (this.checkin.business && val.id === this.checkin.business.id) {
          this.publishVideo = this.checkin.publishVideo
        } else {
          this.publishVideo = val.publishVideoByDefault
        }
      }
    }
  }
}
</script>
