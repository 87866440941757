<template>
  <div class="dashboard-reviews">
    <h2 class="h3 mb-3 font-weight-semi-bold text-dark">Reviews</h2>
    <ReviewsFilters
      v-model="filters"
      :locations="locationsForSelect"
      class="w-100 mb-4" />
    <ReviewStats
      v-if="stats"
      :stats="stats"
      :filter-by-rating-applied="typeof filters.rating === 'number'"
      :filter-by-response-applied="filters.response" />
    <div v-if="reviews.length" class="dashboard-reviews__list">
      <ReviewCard
        v-for="review, index in reviews"
        :review="review"
        :key="index"
        :show-reply-form="activeReplyFormIndex === index"
        :ai-messages="aiMessages"
        @removeAiMessage="removeAiMessage(review.remoteId)"
        @setReplyFormState="setReplyFormState($event, index)"
        @updateReview="updateReview(index, $event)" />
    </div>
    <span v-else>
      No reviews for selected filters
    </span>
    <ReviewModal
      :show="showModal"
      :review="reviewFromEmail"
      :is-reply-approved="isReplyApproved"
      @updateShow="showModal = $event" />
    <Pagination
      :page="currentPage"
      :per-page="perPage"
      :total-count="totalCount"
      @updatePage="updatePage($event, true)" />
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import snakecaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import Pagination from 'vue_widgets/components/pagination'
import ReviewsFilters from './filters'
import ReviewStats from './review_stats'
import ReviewCard from './review_card'
import ReviewModal from './review_modal'

const paramsSerializer = (json) => {
  const snakecasedParams = snakecaseKeys(json, { deep: true })
  return qs.stringify(snakecasedParams, { arrayFormat: 'brackets' })
}

export default {
  props: {
    locationsForSelect: {
      type: Array,
      required: true
    }
  },
  components: {
    ReviewsFilters,
    ReviewStats,
    ReviewCard,
    Pagination,
    ReviewModal
  },
  data() {
    return {
      filters: {},
      reviews: [],
      stats: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      activeReplyFormIndex: false,
      aiMessages: [],
      showModal: false,
      reviewIdFromEmail: null,
      reviewFromEmail: {},
      isReplyApproved: false
    }
  },

  mounted() {
    this.channel = subscribe('GenerateReviewReplyChannel', (msg) => {
      this.aiMessages.push(camelCaseKeys(msg))
    })

    this.reviewIdFromEmail = new URLSearchParams(window.location.search).get('approve_reply')
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  methods: {
    updatePage(page) {
      this.currentPage = page
      this.$nextTick(() => {
        this.getReviews()
          .then(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          })
      })
    },
    submitReply(comment = this.reviewFromEmail.autoGeneratedReply) {
      const { locationRemoteId, remoteId: reviewRemoteId } = this.reviewFromEmail
      const indexReview = this.reviews.findIndex((review) => review.remoteId === reviewRemoteId)

      axios
        .patch(`/api/gmb/locations/${locationRemoteId}/reviews/${reviewRemoteId}`, {
          gmbReview: {
            reviewReply: {
              comment
            }
          }
        }, axiosTransform)
        .then(() => {
          toastr.success('Review reply sent successfully')
          const updatedReview = {
            reviewReply: {
              comment,
              createdAt: new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h12'
              })
            }
          }

          this.updateReview(indexReview, updatedReview)
          this.showModal = true
        })
    },
    getReviews() {
      this.setReplyFormState(false)

      this.getStats()

      return axios.get(
        '/api/reviews_dashboard',
        {
          params: {
            ...this.filters,
            perPage: this.perPage,
            page: this.currentPage
          },
          paramsSerializer
        }
      ).then((res) => {
        this.reviews = res.data
        this.totalCount = parseInt(res.headers['total-count'], 10)
        if (!this.reviewIdFromEmail) return

        this.reviewFromEmail = this.reviews.find((review) => review.remoteId === this.reviewIdFromEmail)

        if (!this.reviewFromEmail) {
          this.showModal = true
          return
        }

        const comment = this.reviewFromEmail.reviewReply?.comment
        if (!comment) {
          this.submitReply()
          return
        }

        this.isReplyApproved = true
        this.showModal = true
      })
    },
    getStats() {
      return axios.get(
        '/api/reviews_dashboard/stats',
        {
          params: this.filters,
          paramsSerializer,
          ...axiosTransform
        }
      ).then((res) => {
        this.stats = res.data
      })
    },
    updateReview(index, review) {
      Object.assign(this.reviews[index], review)
    },
    setReplyFormState(state, index) {
      if (!state) {
        this.activeReplyFormIndex = null
        return
      }

      this.activeReplyFormIndex = index
    },
    removeAiMessage(reviewId) {
      // since we're not using job ids here, we have to remove old messages to allow reply regeneration
      const relatedAiMessageIndex = this.aiMessages.findIndex((message) => message.reviewId === reviewId)
      if (typeof relatedAiMessageIndex !== 'number') return

      this.aiMessages.splice(relatedAiMessageIndex, 1)
    }
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.currentPage = 1
          this.getReviews()
        }, 500)
      },
      deep: true
    }
  }
}
</script>
